import React from "react"
import { Link } from "gatsby"
import styles from "./404.module.css"
import { FormattedMessage } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Overlay from "../components/layout/js/overlay"
import Contacter from "../components/layout/js/contacter"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"

const Merci = () => (
  <>
    <SEO title="Formulaire envoyé" />
    <Header />
    <Overlay />
    <div className="first flex flex-column items-center w-75-ns center ph3">
      <h1 className={styles.titre}>
        <FormattedMessage id="merci.title" />
      </h1>
      <p className={styles.contenu}>
        <FormattedMessage id="merci.content" />
      </p>
      <Link className={styles.bouton} to="/">
        <FormattedMessage id="merci.button" />
      </Link>
    </div>
    <Contacter />
    <Footer />
    <Copyrights />
  </>
)

export default Merci
